



























import { Component, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import Monthpicker from "@/components/Monthpicker.vue";
import InvoiceCards from "@/views/Report/Invoice/InvoiceCards.vue";
import { namespace } from "vuex-class";
import { IDrivingSchoolIncludePayments } from "@/interfaces/IDrivingSchoolIncludePayments";

const DrivingSchoolModule = namespace("driving-school");
const DrivingSchoolFilterModule = namespace("driving-school/localFilter");

@Component({
  components: {
    FscPageHeader,
    Monthpicker,
    InvoiceCards,
  },
})
export default class Invoice extends Vue {
  public name = "Invoice";

  @DrivingSchoolModule.Action("filter")
  public findDrivingSchoolsPayments: any;

  @DrivingSchoolModule.Getter("getDataList")
  public paymentsInfo!: IDrivingSchoolIncludePayments;

  @DrivingSchoolFilterModule.State("filter")
  public includePaymentsLocalFilter: any;

  @DrivingSchoolFilterModule.Mutation("SET_LOCAL_FILTER")
  public setIncludePaymentsLocalFilter!: (payload: Record<any, any>) => void;

  public selectedDate = "";

  public mounted() {
    if (this.includePaymentsLocalFilter instanceof Object) {
      const { date } = this.includePaymentsLocalFilter;
      this.selectedDate = date;
      this.fetchDrivingSchoolIncludePayments(date);
    }
  }

  public selectingDate(date: any): void {
    this.selectedDate = date;
    this.setIncludePaymentsLocalFilter({ date });
    this.fetchDrivingSchoolIncludePayments(date);
  }

  public async fetchDrivingSchoolIncludePayments(fromDate: string) {
    await this.findDrivingSchoolsPayments({
      resource: "driving-schools/current/include-payments",
      filter: { fromDate: fromDate },
    });
  }
}
