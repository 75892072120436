









































































import { Component, Vue, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import InvoiceInfoCard from "@/views/Report/Invoice/InvoiceInfoCard.vue";
import ActionButton from "@/components/Button/ActionButton.vue";
import { downloadFile } from "@/utils/File";
import { IDrivingSchoolIncludePayments } from "@/interfaces/IDrivingSchoolIncludePayments";

@Component({
  components: {
    FscSimpleCard,
    InvoiceInfoCard,
    ActionButton,
  },
})
export default class InvoiceCards extends Vue {
  public name = "InvoiceCards";

  @Prop({ required: true })
  public paymentData!: IDrivingSchoolIncludePayments;

  @Prop()
  paymentDate!: any;

  public alleTitle = "Alle Zahlungen";
  public livePayTitle = "LivePay Zahlungen";

  protected get livePayImgSrc() {
    return require("@/assets/Payment_Live.png");
  }

  protected get simpleLogoImgSrc() {
    return require("@/assets/fsc_simple_logo.png");
  }

  protected onCsvExport(): void {
    downloadFile({
      method: "post",
      url: "reports/payments/csv",
      data: {
        fromDate: this.paymentDate,
      },
    });
  }

  protected get paymentCalculated() {
    let sum =
      this.paymentData.paymentsTotalAmountInEur +
      this.paymentData.classicPayPaymentsMigratedAmountInEur +
      this.paymentData.livePayPaymentsMigratedAmountInEur -
      this.paymentData.classicPayRefundPaymentAmountsInEur -
      this.paymentData.classicPayRefundPaymentsMigratedAmountInEur;

    if (Number.isInteger(sum)) {
      return sum;
    } else {
      return sum.toFixed(2);
    }
  }

  protected get livePayCalculated() {
    let sum = this.paymentData.livePayPaymentsAmountInEur + this.paymentData.livePayPaymentsMigratedAmountInEur;
    if (Number.isInteger(sum)) {
      return sum;
    } else {
      return sum.toFixed(2);
    }
  }

  private get paymentEndDate() {
    return this.paymentData.shortName;
  }
}
