










import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class InvoiceInfoCard extends Vue {
  public name = "InvoiceInfoCard";

  @Prop()
  public imgSrc!: any;

  @Prop()
  public titleText!: any;

  @Prop()
  public sum!: any;
}
